const addColumns = [
  {
    key: 'DateStats',
    label: 'Date',
    show: true,
    headerTitle: 'Date',
    sortable: true,
    thStyle: 'width:90px',
    summed: false,
  },
  {
    key: 'AdvertiserId',
    label: 'Advertiser ID',
    show: false,
    headerTitle: 'Advertiser ID',
    sortable: true,
    thStyle: 'width:110px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'AdvertiserName',
    label: 'Advertiser Name',
    show: true,
    headerTitle: 'Advertiser Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'BanneradId',
    label: 'Banner Ad ID',
    show: false,
    headerTitle: 'Banner Ad ID',
    sortable: true,
    thStyle: 'width:115px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'BanneradName',
    label: 'Banner Ad Name',
    show: true,
    headerTitle: 'Banner Ad Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'VideoadId',
    label: 'Video Ad ID',
    show: false,
    headerTitle: 'Video Ad ID',
    sortable: true,
    thStyle: 'width:110px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'VideoadName',
    label: 'Video Ad Name',
    show: true,
    headerTitle: 'Video Ad Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'NativeadId',
    label: 'Native Ad ID',
    show: false,
    headerTitle: 'Native Ad ID',
    sortable: true,
    thStyle: 'width:110px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'NativeadName',
    label: 'Native Ad Name',
    show: true,
    headerTitle: 'Native Ad Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'RemotertbzoneId',
    label: 'Rmt. RTB Zone ID',
    show: false,
    headerTitle: 'Rmt. RTB Zone ID',
    sortable: true,
    thStyle: 'width:140px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'RemotertbzoneName',
    label: 'Rmt. RTB Zone Name',
    show: true,
    headerTitle: 'Rmt. RTB Zone Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'RemotevideozoneId',
    label: 'Rmt. Video Zone ID',
    show: false,
    headerTitle: 'Rmt. Video Zone ID',
    sortable: true,
    thStyle: 'width:150px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'RemotevideozoneName',
    label: 'Rmt. Video Zone Name',
    show: true,
    headerTitle: 'Rmt. Video Zone Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'PublisherId',
    label: 'Publisher ID',
    show: false,
    headerTitle: 'Publisher ID',
    sortable: true,
    thStyle: 'width:110px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'PublisherName',
    label: 'Publisher Name',
    show: false,
    headerTitle: 'Publisher Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'BannerzoneId',
    label: 'Banner Zone ID',
    show: false,
    headerTitle: 'Banner Zone ID',
    sortable: true,
    thStyle: 'width:130px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'BannerzoneName',
    label: 'Banner Zone Name',
    show: false,
    headerTitle: 'Banner Zone Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'VideozoneId',
    label: 'Video Zone ID',
    show: false,
    headerTitle: 'Video Zone ID',
    sortable: true,
    thStyle: 'width:120px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'VideozoneName',
    label: 'Video Zone Name',
    show: false,
    headerTitle: 'Video Zone Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'RtbzoneId',
    label: 'RTB Zone ID',
    show: false,
    headerTitle: 'RTB Zone ID',
    sortable: true,
    thStyle: 'width:105px',
    tdClass: 'text-right',
    summed: false,
  },
  {
    key: 'RtbzoneName',
    label: 'RTB Zone Name',
    show: false,
    headerTitle: 'RTB Zone Name',
    sortable: false,
    summed: false,
  },
  {
    key: 'CC',
    label: 'Country',
    show: false,
    headerTitle: 'Country',
    sortable: true,
    thStyle: 'width: 85px',
    class: 'text-center',
    summed: false,
  },
  {
    key: 'OsFamily',
    label: 'OS',
    show: false,
    headerTitle: 'OS',
    sortable: true,
    thStyle: 'width:100px',
    summed: false,
  },
  {
    key: 'OsMajor',
    label: 'OS Major',
    show: false,
    headerTitle: 'OS Major',
    sortable: true,
    thStyle: 'width:90px',
    summed: false,
  },
  {
    key: 'OsMinor',
    label: 'OS Minor',
    show: false,
    headerTitle: 'OS Minor',
    sortable: true,
    thStyle: 'width:90px',
    summed: false,
  },
  {
    key: 'BrowserFamily',
    label: 'Browser',
    show: false,
    headerTitle: 'Browser',
    sortable: true,
    thStyle: 'width:130px',
    summed: false,
  },
  {
    key: 'BrowserMajor',
    label: 'Browser Major',
    show: false,
    headerTitle: 'Browser Major',
    sortable: true,
    thStyle: 'width:120px',
    summed: false,
  },
  {
    key: 'RemoteRequests',
    label: 'Rmt. Requests',
    show: true,
    headerTitle: 'Remote Requests',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
    summed: true,
  },
  {
    key: 'Bids',
    label: 'Bids',
    show: true,
    headerTitle: 'Bids',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
    summed: true,
  },
  {
    key: 'Wins',
    label: 'Wins',
    show: false,
    headerTitle: 'Wins',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
    summed: true,
  },
  {
    key: 'Bills',
    label: 'Bills',
    show: false,
    headerTitle: 'Bills',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
    summed: true,
  },
  {
    key: 'Losses',
    label: 'Losses',
    show: false,
    headerTitle: 'Losses',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
    summed: true,
  },
  {
    key: 'Fetches',
    label: 'Fetches',
    show: false,
    headerTitle: 'Fetches',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
    summed: true,
  },
  {
    key: 'Impressions',
    label: 'Impressions',
    show: true,
    headerTitle: 'Impressions',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:110px',
    summed: true,
  },
  {
    key: 'NetImpressions',
    label: 'Net Impressions',
    show: true,
    headerTitle: 'Net Impressions',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:140px',
    summed: true,
  },
  {
    key: 'FilterRate',
    label: 'Filter Rate',
    show: true,
    headerTitle: 'Filter Rate',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    thStyle: 'width:90px',
  },
  {
    key: 'Clicks',
    label: 'Clicks',
    show: true,
    headerTitle: 'Clicks',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:90px',
    summed: true,
  },
  {
    key: 'NetClicks',
    label: 'Net Clicks',
    show: true,
    headerTitle: 'Net Clicks',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:100px',
    summed: true,
  },
  {
    key: 'IpBlockReal',
    label: 'Real IP Block',
    show: true,
    headerTitle: 'Real IP Block',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:110px',
    summed: true,
  },
  {
    key: 'IpMismatch',
    label: 'IP Mismatch',
    show: false,
    headerTitle: 'IP Mismatch',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
    summed: true,
  },
  {
    key: 'UaMismatch',
    label: 'UA Mismatch',
    show: false,
    headerTitle: 'UA Mismatch',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
    summed: true,
  },
  {
    key: 'EmptyReferer',
    label: 'Empty Referer',
    show: false,
    headerTitle: 'Empty Referer',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:120px',
    summed: true,
  },
  {
    key: 'RefererMismatch',
    label: 'Referer Mismatch',
    show: false,
    headerTitle: 'Referer Mismatch',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:140px',
    summed: true,
  },
  {
    key: 'RepeatedImpression',
    label: 'Rptd. Impressions',
    show: false,
    headerTitle: 'Repeated Impressions',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:150px',
    summed: true,
  },
  {
    key: 'RepeatedClick',
    label: 'Rptd. Clicks',
    show: false,
    headerTitle: 'Repeated Clicks',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:110px',
    summed: true,
  },
  {
    key: 'Timeouts',
    label: 'Timeouts',
    show: true,
    headerTitle: 'Timeouts',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:90px',
    summed: true,
  },
  {
    key: 'TimeoutsPercentage',
    label: 'Timeouts %',
    show: true,
    headerTitle: 'Timeouts %',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    thStyle: 'width:100px',
    summed: true,
  },
  {
    key: 'Errors',
    label: 'Errors',
    show: true,
    headerTitle: 'Errors',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:90px',
    summed: true,
  },
  {
    key: 'ErrorsPercentage',
    label: 'Errors %',
    show: true,
    headerTitle: 'Errors %',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    thStyle: 'width:90px',
    summed: true,
  },
  {
    key: 'VastErrors',
    label: 'VAST Errors',
    show: true,
    headerTitle: 'VAST Errors',
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatNumber',
    thStyle: 'width:110px',
    summed: true,
  },
  {
    key: 'Ecpm',
    label: 'eCPM',
    show: true,
    headerTitle: false,
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatCurrency',
    thStyle: 'width:80px',
    summed: false,
  },
  {
    key: 'Spent',
    label: 'Spent',
    show: true,
    headerTitle: false,
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatCurrency',
    thStyle: 'width:90px',
    summed: true,
  },
  {
    key: 'Revenue',
    label: 'Revenue',
    show: true,
    headerTitle: false,
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatCurrency',
    thStyle: 'width:90px',
    summed: true,
  },
  {
    key: 'Profit',
    label: 'Profit',
    show: true,
    headerTitle: false,
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-right',
    formatter: '$formatCurrency',
    thStyle: 'width:90px',
    summed: true,
  },
  {
    key: 'Env',
    label: 'Environment',
    show: true,
    headerTitle: false,
    sortable: true,
    thClass: 'text-center',
    tdClass: 'text-center',
    thStyle: 'width:90px',
  },
]
export default addColumns
